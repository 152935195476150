import self from "../img/self.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */
/*#000046 #1CB5E0*/
export let colors = ["#1CB5E0", "#D10000"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Dave",
    lastName: "Cheng",
    initials: "DC", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Full Stack Developer/Data Scientist",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'based in Canada'
        },
        {
            emoji: "🐕",
            text: "has a very loud shiba"
        },
        {
            emoji: "📧",
            text: "davidcheng1990@gmail.com"
        }
    ],
    socials: [

        {
            link: "https://www.instagram.com/bearofthelake/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/dave586",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://dave-the-dev.medium.com/",
            icon: "fa fa-medium",
            label: 'Medium'
        },

// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hello and welcome! I'm Dave. I'm a software engineer with a keen eye for data and statistics. I studied CompSci at the University of Victoria, enjoy long walks on the beach, and believe integrity is at the root of any tangible achievement. So if you are interested in project collaborations relating to data science, software development, or general goofiness, feel free to give me a shout!",
    skills:
        {
            proficientWith: ['Javascript', 'React.js', 'Git', 'Github', 'Bootstrap', 'HTML5', 'CSS3','Python','Tableau','SQL','JAVA','Shell Scripting'],
            exposedTo: ['Node.js', 'MongoDB', 'QCEngine', 'TLA+', 'LATEX', 'AWS']
        }
    ,
    hobbies: [
        {
            label: '3d printing',
            emoji: '🖨️'
        },
        {
            label: 'hiking',
            emoji: '🏕️'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },

        {
            label:'photography',
            emoji: '📸'
        },
        {
            label:'fishing',
            emoji: '🎣'
        }

// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ], shoutout: 'This page was made using React and Node js. It is a complete single-page web app with efficiency in mind. ',



    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Research Project",
            live: "https://dspace.library.uvic.ca/handle/1828/13926?show=full", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://dspace.library.uvic.ca/bitstream/handle/1828/13926/Cheng_Yue_Msc_2022.pdf.pdf?sequence=1&isAllowed=y", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        },
        {
            title: "Data Extraction",
            live: "https://github.com/dave586/trendingscrape",
            source: "https://github.com/dave586/trendingscrape",
            image: mock2
        },
        {
            title: "Data Science Project",
            live: "https://github.com/dave586/realty_analytics",
            source: "https://github.com/dave586/realty_analytics",
            image: mock3
        },
        {
            title: "Oil Painting Webapp Project",
            live: "https://github.com/dave586/webdev",
            source: "https://github.com/dave586/webdev",
            image: mock4
        },
        {
            title: "3D Printing Project",
            live: "",
            source: "",
            image: mock5
        }
    ]
}